import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TRACKER } from '../../App.config'

// Import Components
import Box from '@material-ui/core/Box'
import StyledSelectMenu from '../common/StyledSelectMenu'
import StyledInput from '../common/StyledInput'
import Button from '@material-ui/core/Button'

// Import Actions & Methods
import { setRouteUserInfo, getAndDisplayUserRoute, clearRouteDataset, saveRouteAnimationConfig } from '../../store/actions/routeActions'
import { setTraceLayerVisibility } from '../../store/actions/layerActions'

class RouteSelectMenu extends React.PureComponent {
    // Handle On Change
    handleOnChange = event => {
        this.props.dispatch( setRouteUserInfo({ [event.target.name]: event.target.value }) )
    }

    // Handle On Submit
    handleOnSubmit = event => {
        event.preventDefault()

        // If `None` Selected
        let { userId, startTime, endTime, routeView } = this.props
        if(userId < 0 || !startTime || !endTime || !routeView) {
            return
        }

        // Transform Date String
        startTime = this._transformDateString(startTime)
        endTime = this._transformDateString(endTime)

        // Handle User Route
        this.props.dispatch( getAndDisplayUserRoute({ userId, startTime, endTime, routeView }) )
    }

    // Handle Clear Route
    handleClearRoute = () => {
        // Reset Form
        this.props.dispatch( setRouteUserInfo({ userId: -1, startTime: '', endTime: '' }) )
        
        // Clear Route Dataset
        this.props.dispatch( clearRouteDataset() )

        // Reset Saved Animation Config
        this.props.dispatch( saveRouteAnimationConfig({}) )

        // Hide Trace Layer
        this.props.dispatch( setTraceLayerVisibility(false) )
    }

    ///////////////
    // Utilities //
    ///////////////

    // Transform Date String
    _transformDateString = date => {
        date = date.replace('T', ' ')
        date = date + ':00'
        return date
    }

    render() {
        const { userId, startTime, endTime, users, routeView } = this.props

        return (
            <Box { ...containerStyles }>
                <form onSubmit={ this.handleOnSubmit }>
                    <Box width='100%' margin='0px' padding='8px 0px 8px 0px'>
                        <StyledSelectMenu
                            menuItems={[ { value: -1, label: 'None' }, ...users ]}
                            label='Select User'
                            name='userId'
                            required={ true }
                            value={ userId }
                            onChange={ this.handleOnChange }
                        />
                    </Box>

                    <Box width='100%' margin='0px' padding='8px 0px 8px 0px'>
                        <StyledInput
                            name='startTime'
                            label='Start Time'
                            type='datetime-local'
                            size='small'
                            variant='outlined'
                            required={ true }
                            inputProps={{ style: { height: '19px' } }}
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '100%' }}
                            value={ startTime }
                            onChange={ this.handleOnChange }
                        />
                    </Box>

                    <Box width='100%' margin='0px' padding='8px 0px 8px 0px'>
                        <StyledInput
                            name='endTime'
                            label='End Time'
                            type='datetime-local'
                            size='small'
                            variant='outlined'
                            required={ true }
                            inputProps={{ style: { height: '19px' } }}
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '100%' }}
                            value={ endTime }
                            onChange={ this.handleOnChange }
                        />
                    </Box>

                    <Box width='100%' margin='0px' padding='8px 0px 8px 0px'>
                        <StyledSelectMenu
                            menuItems={[
                                { value: 'Polyline View', label: 'Polyline View' },
                                { value: 'Points View', label: 'Points View' }
                            ]}
                            label='Select Route View'
                            name='routeView'
                            required={ true }
                            value={ routeView }
                            onChange={ this.handleOnChange }
                        />
                    </Box>

                    <Box width='100%' margin='0px' padding='8px 0px 8px 0px'>
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            fullWidth={ true }
                            style={{ color: '#fff' }}
                        >
                            { 'Get Route' }
                        </Button>
                    </Box>

                    <Box width='100%' margin='0px' padding='8px 0px 8px 0px'>
                        <Button
                            type='reset'
                            color='primary'
                            variant='outlined'
                            fullWidth={ true }
                            style={{ color: '#fff' }}
                            onClick={ this.handleClearRoute }
                        >
                            { 'Clear' }
                        </Button>
                    </Box>
                </form>
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    margin: 0,
    padding: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

// Prop Types
RouteSelectMenu.propTypes = {
    userId: PropTypes.number,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    users: PropTypes.array,
    routeView: PropTypes.string
}

RouteSelectMenu.defaultProps = {
    userId: -1,
    startTime: '',
    endTime: '',
    users: [],
    routeView: 'Polyline View'
}

const mapStateToProps = state => ({
    userId: state.app.route.routeUserInfo.userId,
    startTime: state.app.route.routeUserInfo.startTime,
    endTime: state.app.route.routeUserInfo.endTime,
    users: state.keplerGl.map &&
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ] &&
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ].allData ?
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ].allData.map(i => ({ value: i[0], label: i[1] }))
        .sort((a, b) => {
            const labelA = a.label.toLowerCase()
            const labelB = b.label.toLowerCase()

            if(labelA < labelB) {
                return -1
            }

            if(labelA > labelB) {
                return 1
            }

            return 0
        })
        : [],
    routeView: state.app.route.routeUserInfo.routeView
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(RouteSelectMenu)