// App Configurations
export const MAP_CONFIG = {
    MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN || null,
}

// Tracker Configurations
export const TRACKER = {
    KEY: 'mykey',
    HOST: 'backend.barikoi.com',
    PORT: 6002,
    AUTH_ENDPOINT: 'https://backend.barikoi.com:8888/api/broadcasting/auth',
    CLUSTER: 'ap2',
    DATA_ID: 'barikoi_tracker_data',
    DATA_LABEL: 'Barikoi Tracker Data',
    AUTH_API: 'https://backend.barikoi.com:8888/api/v1/user/defaults',
    TRACKER_API: 'https://backend.barikoi.com:8888/api/v1/users-data',
    ACTIVE_USERS_API: 'https://backend.barikoi.com:8888/api/v1/active-users'
}

// Authentication Configs
export const AUTH = {
    LOGIN_API: 'https://backend.barikoi.com:8888/api/v1/login',
    TRACE_COMPANY_ADMIN_LOGIN_API: 'https://backend.barikoi.com:8888/api/v1/dashboard/login'
}

// Route Configs
export const ROUTE = {
    POLYLINE_API_URL: 'https://backend.barikoi.com:8888/api/v1/get-rdp-route',
    POINTS_API_URL: 'https://backend.barikoi.com:8888/api/v1/route/point',
    DATA_ID: 'route_data',
    DATA_LABEL: 'Route',
    ENDPOINTS_DATA_ID: 'endpoints_data',
    ENDPOINTS_DATA_LABEL: 'Endpoints'
}

// Company Dashboard Configs
export const COMPANY_DASHBOARD = {
    GET_ANALYTICS: 'https://backend.barikoi.com:8888/api/v1/analytics',
    GET_ANALYTICS_WITHIN_TIME_RANGE: 'https://backend.barikoi.com:8888/api/v1/analytics/time-wise',
    ADD_USER_API: 'https://backend.barikoi.com:8888/api/v1/new/user',
    GET_ALL_USERS_API: 'https://backend.barikoi.com:8888/api/v1/users',
    UPDATE_USER_API: 'https://backend.barikoi.com:8888/api/v1/user/update',
    CREATE_GROUP_API: 'https://backend.barikoi.com:8888/api/v1/group/add',
    GET_ALL_GROUPS_API: 'https://backend.barikoi.com:8888/api/v1/company/groups',
    ASSIGN_USER_TO_GROUP_API: 'https://backend.barikoi.com:8888/api/v1/group/user/add',
    GET_COMPANY_SETTINGS_API: 'https://backend.barikoi.com:8888/api/v1/settings',
    UPDATE_COMPANY_SETTINGS_API: 'https://backend.barikoi.com:8888/api/v1/company-settings',
    GET_USER_TRIPS_WITHIN_TIME_RANGE_API: 'https://barikoi.xyz/v1/api/trace/user/trips',
    TRACE_USER_EVENT_API: 'https://backend.barikoi.com:8888/api/v1/single/event',
    GET_GROUP_USERS_API: 'https://backend.barikoi.com:8888/api/v1/company/group/users',
    TRACE_GROUP_EVENT_API: 'https://backend.barikoi.com:8888/api/v1/group/event',
    TRACE_ALL_USERS_EVENT_API: 'https://backend.barikoi.com:8888/api/v1/user/all/event',
    GET_API_KEY_FROM_TOKEN_API: 'https://backend.barikoi.com:8888/api/v1/token'
}