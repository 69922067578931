import * as ActionTypes from '../actions/actionTypes'
import { unionArrayOfObjects } from '../../utils/utils'

const initialState = {
    isTrackerOn: true,
    users: [],
    selectedUser: {}
}

const trackerReducer = (state=initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_IS_TRACKER_ON:
            return {
                ...state,
                isTrackerOn: action.payload.isTrackerOn
            }
        
        case ActionTypes.UPDATE_USERS:
            const users = action.payload.users.filter(u => u.longitude && u.latitude)
            return {
                ...state,
                users: unionArrayOfObjects(state.users, users, 'user_id')
            }
        
        case ActionTypes.SET_SELECTED_USER:
            return {
                ...state,
                selectedUser: action.payload.selectedUser
            }

        default:
            return state
    }
}

export default trackerReducer