import { combineReducers } from 'redux'
import sidePanelReducer from './sidePanelReducer'
import trackerReducer from './trackerReducer'
import authReducer from './authReducer'
import routeReducer from './routeReducer'
import dataReducer from './dataReducer'

const appReducer = combineReducers({
    sidePanel: sidePanelReducer,
    tracker: trackerReducer,
    auth: authReducer,
    route: routeReducer,
    data: dataReducer
})

export default appReducer