import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

class CountCard extends React.PureComponent {
    render() {
        const { title, count } = this.props

        return (
            <Box { ...containerStyles }>
                <Card variant='outlined' style={{ width: '100%', background: 'none', border: 'none' }}>
                    <CardContent style={{ padding: '8px' }}>
                        <Typography variant='subtitle2' style={{ color: '#fff' }}>{ title }</Typography>
                        <Typography variant='h6' style={{ color: '#2ddbac' }}>{ count }</Typography>
                    </CardContent>
                </Card>
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    margin: 0,
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

// Prop Types
CountCard.propTypes = {
    title: PropTypes.string,
    count: PropTypes.number
}

CountCard.defaultProps = {
    title: 'Count',
    count: 0
}

export default CountCard