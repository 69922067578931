import axios from 'axios'
import { AUTH } from '../../App.config'
import * as ActionTypes from './actionTypes'

// Handle Login Action
export function handleLogin({ email, password }) {
    return dispatch => {
        // Request Login Access
        axios.post(AUTH.TRACE_COMPANY_ADMIN_LOGIN_API, { email, password })
            .then(res => {
                // If Login Success
                if(res.data.success) {
                    const token = res.data.data

                    // Save to localStorage
                    localStorage.setItem('userEmail', email)
                    localStorage.setItem('token', token)

                    // Redirect to Trace App
                    window.location.replace('/')
                }
            })
            .catch(err => {
                const errorMessage = err.response && err.response.data ? err.response.data.message : 'Error occurred.'
                dispatch( setAuthError(errorMessage) )
                console.error(errorMessage)
            })
    }
}

// Handle Logout
export function handleLogout() {
    return dispatch => {
        // Clear localStorage
        localStorage.clear()

        // Redirect to login
        window.location.replace('/login')
    }
}

// Set Auth Error
export function setAuthError(message) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_AUTH_ERROR, payload: { message } })
    }
}

/////////////////////////
// Helper Functions //
////////////////////////

// Validate auth token
export function tokenValidation(token) {
    return axios.get(AUTH.LOGIN_API, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(res => {
            if (res.status === 200) {
                return true
            }
            return false
        })
        .catch(err => {
            console.error(err)
            return false
        })
}