import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

// Import Components
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import StyledInput from '../common/StyledInput'

// Import Actions & Methods
import { setSelectedUser } from '../../store/actions/trackerActions'

class UserList extends React.PureComponent {
  state = {
    users: [],
    userSearch: ''
  }

  componentDidMount() {
    // Initial Sort Users By Updated At
    this._updateUsers()

    // Sort Users By Updated At Periodically
    setInterval(() => {
      this._updateUsers()
    }, 30000)
  }

  componentDidUpdate(prevProps) {
    const { users } = this.props

    // If Users Populated
    if(prevProps.users.length !== users.length) {
      // Initial Sort Users By Updated At
      this._updateUsers()
    }
  }

  // On Change
  _onChange = event => {
    this.setState({ [ event.target.name ]: event.target.value })
  }

  // Sort Users By Last Updated
  _sortByLastUpdated = users => {
    const sortedUsers = users.sort((u1, u2) => {
      const updatedAt1 = u1.updated_at ? u1.updated_at.trim() : ''
      const updatedAt2 = u2.updated_at ? u2.updated_at.trim() : ''

      if (updatedAt1 > updatedAt2) {
        return -1
      }

      if (updatedAt1 < updatedAt2) {
        return 1
      }

      return 0
    })

    return sortedUsers
  }

  // Filter User List By Search
  _filterUserList = (users, userSearch) => {
    if(!userSearch) {
      return users
    }

    const filteredUsers = users.filter(u => {
      const _userSearch = userSearch.toLowerCase().trim()
      const _userName = u.name ? u.name.toLowerCase().trim() : ''

      return _userName.includes(_userSearch)
    })

    return filteredUsers
  }

  // On User Click
  _onUserClick = selectedUser => {
    const { dispatch } = this.props
    
    // Dispatch To Tracker Reducer
    dispatch( setSelectedUser(selectedUser ? selectedUser : {}) )
  }

  // Update Users Periodically
  _updateUsers = () => {
    const { users } = this.props
    const _users = this._sortByLastUpdated(users)
    this.setState({ users: _users })
  }

  render() {
    const { users } = this.state
    const { userSearch } = this.state
    const filteredUsers = this._filterUserList(users, userSearch)

    return (
      <Box>
        <StyledInput
          name='userSearch'
          fullWidth={ true }
          variant='outlined'
          label='Search User'
          margin='dense'
          size='small'
          value={ userSearch }
          onChange={ this._onChange }
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon style={{ color: 'rgba(45, 219, 172, 0.6)' }}/>
              </InputAdornment>
            )
          }}
        />

        <List
          dense={ true }
          disablePadding={ true }
          style={{
            ...listStyles,
            overflowY: filteredUsers.length > 5 ? 'auto' : 'hidden'
          }}
        >
          {
            filteredUsers.length <= 0 ?
            (
              <ListItem
                dense={ true }
                disableGutters={ true }
                style={ listItemStyles }
              >
                <ListItemText
                  primary='No user found.'
                  primaryTypographyProps={{
                    style: {
                      color: '#f0f0f0',
                      fontSize: '12px'
                    }
                  }}
                />
              </ListItem>
            )
            :
            filteredUsers.map((u, i) =>
              <ListItem
                key={ i }
                button={ true }
                dense={ true }
                disableGutters={ true }
                style={ listItemStyles }
                onClick={ () => this._onUserClick(u) }
              >
                <ListItemAvatar style={{ margin: 0, display: 'flex' }}>
                  <StyledBadge
                    overlap='circle'
                    anchorOrigin={{
                      horizontal: 'right',
                      vertical: 'bottom'
                    }}
                    variant='dot'
                    active_status={ u.active_status }
                  >
                    <Avatar
                      style={{
                        margin: 0,
                        width: '36px',
                        height: '36px'
                      }}
                    />
                  </StyledBadge>
                </ListItemAvatar>
                <ListItemText
                  primary={ u.name }
                  secondary={ `Last Updated: ${ u.updated_at }` }
                  primaryTypographyProps={{
                    style: {
                      color: '#f0f0f0',
                      fontSize: '12px'
                    }
                  }}
                  secondaryTypographyProps={{
                    style: {
                      color: '#808080',
                      fontSize: '11px'
                    }
                  }}
                />
              </ListItem>
            )
          }
        </List>
      </Box>
    )
  }
}

// Styled Components
const StyledBadge = withStyles({
  badge: {
    backgroundColor: ({ active_status }) => active_status === 'online' ? '#1fbad6' : '#c90000',
    color: ({ active_status }) => active_status === 'online' ? '#1fbad6' : '#c90000',
    boxShadow: `0 0 0 2px #f0f0f0`,
    '&::after': {
      position: 'absolute',
      display: ({ active_status }) => active_status === 'online' ? 'block' : 'none',
      top: '-1px',
      left: '-1px',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: "''"
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
})(Badge)

// JSS Styles
const listStyles = {
  boxSizing: 'border-box',
  width: '100%',
  maxHeight: '240px',
  border: '1px solid rgba(45, 219, 172, 0.4)',
  borderRadius: '4px'
}

const listItemStyles = {
  padding: '0px 8px',
  width: '100%'
}

// Prop Types
UserList.propTypes = {
  users: PropTypes.array,
  dispatch: PropTypes.func
}

UserList.defaultProps = {
  users: [],
  dispatch: () => null
}

const mapStateToProps = state => ({
  users: state.app.tracker.users
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(UserList)