// Validate email
export function validateEmail(email) {
    email = email.trim()
    const verdict = { success: false, message: '' }

    if(email) {
        if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            verdict.success = true
            verdict.message = ''

        } else {
            verdict.success = false
            verdict.message = 'Invalid email address.'
        }

    } else {
        verdict.success = false
        verdict.message = 'Required field.'
    }

    return verdict
}

// Validate Password
export function validatePassword(password) {
    const verdict = { success: false, message: '' }

    if(password) {
        if (password.length < 6) {
            verdict.success = false
            verdict.message = 'Password must be atleast 6 digit!'

        } else {
            verdict.success = true
            verdict.message = ''
        }

    } else {
        verdict.success = false
        verdict.message = 'Required field.'
    }

    return verdict
}

// Validate Phone Number
export function validatePhoneNumber(phoneNumber) {
    const regExp = /^\d+$/
    const found = phoneNumber.match(regExp)
    const verdict = {
        success: true,
        message: ''
    }

    if(!phoneNumber) {
        verdict.success = false
        verdict.message = 'Empty field.'

    } else if(phoneNumber.length !== 11) {
        verdict.success = false
        verdict.message = 'Must be 11 characters.'

    } else if(!found) {
        verdict.success = false
        verdict.message = 'Numbers only.'
    }

    return verdict
}

// Validate Tracking Days
export function validateTrackingDays(trackingDays) {
    const validTrackingDays = [ 'sat', 'sun', 'mon', 'tue', 'wed', 'thu', 'fri' ]
    const verdict = { success: false, message: '' }

    if(trackingDays) {
        const days = trackingDays.split(',').map(d => d.trim().toLowerCase())
        let valid = true
        
        days.forEach(d => {
            if(!validTrackingDays.includes(d)) {
                valid = false
            }
        })

        if(valid) {
            verdict.success = true
            verdict.message = ''

        } else {
            verdict.success = false
            verdict.message = 'Must contain first 3 letters of days separated by ,.'
        }

    } else {
        verdict.success = false
        verdict.message = 'Required field.'
    }

    return verdict
}

// Union Array Of Objects By Key
export function unionArrayOfObjects(array1, array2, key) {
    const array = [ ...array1 ]
    array2.forEach(o => {
        const index = array.findIndex(a => a[ key ] === o[ key ])
        if(index >= 0) {
            array[ index ] = o

        } else {
            array.push(o)
        }
    })
    
    return array
}