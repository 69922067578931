import { injectComponents, ModalContainerFactory, SidePanelFactory } from 'kepler.gl/components'

// Define null factory to not render any unneсessary components
const NullComponent = () => null
const NullComponentFactory = () => NullComponent

const KeplerGl = injectComponents([
    [ ModalContainerFactory, NullComponentFactory ],
    [ SidePanelFactory, NullComponentFactory ]
])

export default KeplerGl