import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import KeplerGlMap from '../components/KeplerGl/KeplerGlMap'

// Import Actions & Methods
import { activateTracker, deactivateTracker } from '../store/actions/trackerActions'

class TraceApp extends React.PureComponent {
  componentDidMount() {
    // Activate Tracker
    const { isTrackerOn } = this.props
    if(isTrackerOn) {
      this.props.dispatch( activateTracker() )
    }
  }

  componentWillUnmount() {
    // Deactivate Tracker Channel
    this.props.dispatch( deactivateTracker() )
  }

  render() {
    return (
      <Box { ...appContainerStyles }>
        <KeplerGlMap />
      </Box>
    )
  }
}

// JSS Styles
const appContainerStyles = {
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden'
}

// Prop Types
TraceApp.propTypes = {
  isTrackerOn: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({ isTrackerOn: state.app.tracker.isTrackerOn })
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TraceApp)