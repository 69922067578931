import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import StyledTabsMenu from '../common/StyledTabsMenu'
import DashboardPanel from './DashboardPanel'
import AnalyticsPanel from './AnalyticsPanel'

// Import Actions & Methods
import { setSelectedTab } from '../../store/actions/sidePanelActions'
import { setTraceLayerVisibility, setRouteLayerVisibility } from '../../store/actions/layerActions'

class Body extends React.PureComponent {
    componentDidUpdate(prevProps) {
        const { selectedTab } = this.props

        // If Tab Changes
        if(prevProps.selectedTab !== selectedTab) {
            if(selectedTab === 0) {
                // If Trace Tab then show only Trace layer
                this.props.dispatch( setRouteLayerVisibility(false) )
                this.props.dispatch( setTraceLayerVisibility(true) )

            } else if(selectedTab === 1) {
                 // If Route Tab then show only Route layer
                this.props.dispatch( setTraceLayerVisibility(false) )
                this.props.dispatch( setRouteLayerVisibility(true) )
            }
        }
    }

    // Handle Tab On Change
    handleTabOnChange = (event, newValue) => {
        this.props.dispatch( setSelectedTab(newValue) )
    }

    render() {
        const { selectedTab, isDataLoading } = this.props

        return (
            <Box { ...containerStyles }>
                <Box height='40px' width='100%'>
                    <StyledTabsMenu iconSize='16px' value={ selectedTab } onChange={ this.handleTabOnChange } />
                </Box>

                { isDataLoading &&
                    <Box width='100%'>
                        <LinearProgress />
                    </Box>
                }

                { selectedTab === 0 &&
                    <DashboardPanel />
                }
                { selectedTab === 1 &&
                    <AnalyticsPanel />
                }
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'auto'
}

// Prop Types
Body.propTypes = {
    selectedTab: PropTypes.number,
    isDataLoading: PropTypes.bool
}

Body.defaultProps = {
    selectedTab: 0,
    isDataLoading: false
}

const mapStateToProps = state => ({
    selectedTab: state.app.sidePanel.selectedTab,
    isDataLoading: state.app.data.isDataLoading
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Body)