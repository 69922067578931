import React from 'react'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import MapOutlinedIcon from '@material-ui/icons/MapOutlined'
import MapStyleRadioMenu from './MapStyleRadioMenu'

// Import Actions & Methods
import { setCurrentMapStyle } from '../../store/actions/mapActions'

class MapStyleActionPanel extends React.PureComponent {
    state = {
        isHovered: false,
        isOpen: false,
        iconColor: '#6A7485',
        background: '#29323C',
        selectedMap: 'barikoi_dark'
    }

    componentDidUpdate(prevProps, prevState) {
        const { isHovered, isOpen } = this.state

        // If isHovered changes
        if(prevState.isHovered !== isHovered || prevState.isOpen !== isOpen) {
            // Set Hover Color Based on isHovered or isOpen State
            this._setHoverColor(isHovered, isOpen)
        }
    }

    // Handle On Map Style Change
    handleOnRadioChange = event => {
        this.setState({ selectedMap: event.target.value })

        // Dispatch Map Style Change
        this.props.dispatch( setCurrentMapStyle(event.target.value) )
    }

    ///////////////
    // Utilities //
    ///////////////

    // Set Is Hovered onMouseEnter or Leave
    _setIsHoveredTrue = () => {
        this.setState({ isHovered: true })
    }

    _setIsHoveredFalse = () => {
        this.setState({ isHovered: false })
    }

    // Set Hover Color
    _setHoverColor = (isHovered, isOpen) => {
        let iconColor = ''
        let background = ''

        if(isHovered || isOpen) {
            iconColor = '#FFFFFF'
            background = '#3A4552'

        } else {
            iconColor = '#6A7485'
            background = '#29323C'
        }

        this.setState({ iconColor, background })
    }

    // Set isOpen if Clicked
    _setIsOpenToggle = event => {
        event.preventDefault()
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    render() {
        const { isOpen, iconColor, background, selectedMap } = this.state

        return (
            <Box style={ containerStyles }>
                { isOpen &&
                    <Box marginRight='8px'>
                        <MapStyleRadioMenu selectedMap={ selectedMap } handleOnRadioChange={ this.handleOnRadioChange } />
                    </Box>
                }

                <Box
                    onMouseEnter={ this._setIsHoveredTrue }
                    onMouseLeave={ this._setIsHoveredFalse }
                    style={{ ...iconContainerStyles, background, cursor: 'pointer' }}
                >
                    <IconButton
                        size='small'
                        onClick={ this._setIsOpenToggle }
                    >
                        <MapOutlinedIcon style={{ color: iconColor }} />
                    </IconButton>
                </Box>
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start'
}

const iconContainerStyles = {
    width: '32px',
    height: '32px',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(MapStyleActionPanel)