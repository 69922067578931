import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'

class StyledSelectMenu extends React.PureComponent {
    render() {
        const { menuItems, label, value, onChange, required, name, multiple } = this.props
        return (
            <StyledFormControl
                variant='outlined'
                fullWidth={ true }
                margin='dense'
                size='small'
                required={ required }
            >
                <StyledInputLabel
                    id='select-menu-label'
                    margin='dense'
                >
                    { label }
                </StyledInputLabel>
                <StyledSelect
                    id='select-menu'
                    labelId='select-menu-label'
                    label={ label }
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        },
                        getContentAnchorEl: null,
                        style: { maxHeight: '320px' }
                    }}
                    inputProps={{ name }}
                    multiple={ multiple }
                    value={ value }
                    onChange={ onChange }
                >
                    { (!menuItems || menuItems.length === 0) &&
                        <StyledMenuItem dense={ true } value=''><em>{ 'None' }</em></StyledMenuItem>
                    }

                    {
                        menuItems.map((item, index) =>
                            <StyledMenuItem
                                key={ index }
                                dense={ true }
                                value={ item.value ? item.value : item }
                            >
                                { item.label ? item.label : item }
                            </StyledMenuItem>
                        )
                    }
                </StyledSelect>
            </StyledFormControl>
        )
    }
}

// JSS Styled Components
const StyledFormControl = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: '1px solid',
                borderColor: 'rgba(45, 219, 172, 0.4)'
            },
            '&:hover fieldset': {
                border: '1px solid',
                borderColor: 'rgba(45, 219, 172, 0.8)'
            },
            '&.Mui-focused fieldset': {
                border: '2px solid',
                borderColor: 'rgba(45, 219, 172, 0.8)'
            }
        }
    }
})(FormControl)

const StyledInputLabel = withStyles({
    root: {
        color: 'rgba(45, 219, 172, 0.8)',
        fontSize: '14px'
    }
})(InputLabel)

const StyledSelect = withStyles({
    root: {
        background: 'rgba(56, 64, 78, 0.2)'
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.8)'
    },
    outlined: {
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: '14px'
    }
})(Select)

const StyledMenuItem = withStyles({
    root: {
        fontSize: '12px',
        color: 'rgba(56, 64, 78, 0.8)'
    }
})(MenuItem)

// Prop Types
StyledSelectMenu.propTypes = {
    menuItems: PropTypes.array,
    label: PropTypes.string,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.array ]),
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    name: PropTypes.string,
    multiple: PropTypes.bool
}

StyledSelectMenu.defaultProps = {
    menuItems: [],
    label: 'Select',
    value: [],
    required: false,
    name: 'select',
    multiple: false
}

export default StyledSelectMenu