import * as ActionTypes from '../actions/actionTypes'

const initialState = {
    routeUserInfo: {
        userId: -1,
        startTime: '',
        endTime: '',
        routeView: 'Polyline View'
    },
    savedAnimationConfig: {},
    error: {}
}

const routeReducer = (state=initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_ROUTE_USER_INFO:
            return {
                ...state,
                routeUserInfo: { ...state.routeUserInfo, ...action.payload.routeUserInfo }
            }
        
        case ActionTypes.SET_SAVED_ANIMATION_CONFIG:
            return {
                ...state,
                savedAnimationConfig: action.payload.savedAnimationConfig
            }
        
        case ActionTypes.SET_ROUTE_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        
        default:
            return state
    }
}

export default routeReducer