import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

// Import Styles
import './App.css'

// Import Components
import Box from '@material-ui/core/Box'
import TraceApp from './pages/TraceApp'
import TraceLogin from './pages/TraceLogin'
import CircularProgress from '@material-ui/core/CircularProgress'

// Import Actions & Methods
import { tokenValidation } from './store/actions/authActions'

class App extends React.PureComponent {
  state = {
    isAuthenticated: false,
    isLoading: true
  }

  componentDidMount() {
    // Check If login token still valid
    const token = localStorage.getItem('token')

    if(token) {
      this.setState({ isLoading: true })

      // Validate Token
      tokenValidation(token)
        .then(isAuthenticated => {
          this.setState({ isAuthenticated, isLoading: false })
        })
        .catch(err => {
          console.error(err)
          this.setState({ isAuthenticated: false, isLoading: false })
        })

    } else {
      this.setState({ isAuthenticated: false, isLoading: false })
    }
  }

  render() {
    const { isAuthenticated, isLoading } = this.state
    const token = localStorage.getItem('token')

    return (
      <Box { ...appContainerStyles }>
        <BrowserRouter>
          <Switch>
            <Route exact path='/'>
              {
                token && isAuthenticated && !isLoading ?
                <TraceApp /> :
                isLoading ?
                <CircularProgress /> :
                <Redirect to='/login' />
              }
            </Route>

            <Route exact path='/login'>
              {
                token && isAuthenticated && !isLoading ?
                <Redirect to='/' /> :
                isLoading ?
                <CircularProgress /> :
                <TraceLogin />
              }
            </Route>
          </Switch>
        </BrowserRouter>
      </Box>
    )
  }
}

// JSS Styles
const appContainerStyles = {
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden'
}

export default App