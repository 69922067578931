import * as ActionTypes from './actionTypes'
import axios from 'axios'
import Pusher from 'pusher-js'
import { TRACKER } from '../../App.config'
import { processRowObject } from 'kepler.gl/processors'
import { addDataToMap, layerTextLabelChange, updateMap, removeLayer, interactionConfigChange, layerVisConfigChange, layerVisualChannelConfigChange, layerConfigChange } from 'kepler.gl/actions'
import { getLayerType } from './layerActions'
import { setIsDataLoading } from './dataActions'

// Dispatch `SET_IS_TRACKER_ON`
export function setIsTrackerOn(isTrackerOn) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_IS_TRACKER_ON, payload: { isTrackerOn } })
    }
}

// Activate Tracker
export function activateTracker() {
    return (dispatch, getState) => {
        // Get Auth Token
        const authToken = getAuthToken()

        // Set isDataLoading
        dispatch( setIsDataLoading(true) )

        // Hit API to get the latest Position for all first time
        axios.get(TRACKER.TRACKER_API, { headers: { Authorization: `Bearer ${ authToken }` } })
            .then(res => {
                // Update Tracker Data First Time From API
                const newData = res.data.users

                // Transform Data
                const transformedNewData = transformApiData(newData)

                dispatch( updateTrackerData(transformedNewData) )

                ////////////////////////////
                // Set Layer Configs Once //
                ////////////////////////////
                // Dispatch Layer Color Range
                const trackerLayer = getState().keplerGl.map.visState.layers.find(el => el.config.dataId === TRACKER.DATA_ID)

                const colorRange = {
                    category: 'Barikoi',
                    colors: [ '#c90000', '#1fbad6' ],
                    name: 'Trace Online/Offline',
                    type: 'quantile'
                }

                dispatch( layerVisConfigChange(trackerLayer, { colorRange }) )

                // Set Color Field by to distinguish online/offline
                const trackerDataset = getState().keplerGl.map.visState.datasets[ TRACKER.DATA_ID ]
                const activeStatusField = trackerDataset.fields.find(el => el.name === 'active_status')
                const newConfig = {
                    colorField: {
                        analyzerType: activeStatusField.analyzerType,
                        format: activeStatusField.format,
                        id: activeStatusField.id,
                        name: activeStatusField.name,
                        tableFieldIndex: activeStatusField.tableFieldIndex,
                        type: activeStatusField.type
                    }
                }
    
                // Dispatch Color Field By
                dispatch( layerVisualChannelConfigChange(trackerLayer, newConfig, 'color') )
            })
            .then(() => {
                // Set isDataLoading
                dispatch( setIsDataLoading(false) )

                // Activate Socket Connection
                dispatch( activateSocket() )

                // Check Online Status
                setInterval(function checkOnline() {
                    dispatch( checkOnlineStatus() )
                    return checkOnline
                }(), 30000)
            })
            .catch(err => console.error(err))
    }
}

// De-activate Tracker
export function deactivateTracker() {
    return () => {
        if(window.pusherChannel) {
            window.pusher.unsubscribe(window.pusherChannel)
            delete window.pusher
            delete window.pusherChannel
        }
    }
}

// Activate Tracker Socket
function activateSocket() {
    return dispatch => {
        // Get Auth Token
        const authToken = getAuthToken()

        // Get Channel and Event from API
        axios.get(TRACKER.AUTH_API, { headers: { Authorization: `Bearer ${ authToken }` } })
            .then(res => {
                const channel = res.data.info.connections.channel.name
                const event = res.data.info.connections.channel.events.find(item => item.type === 1).name

                window.pusher = new Pusher(TRACKER.KEY, {
                    wsHost: TRACKER.HOST,
                    wsPort: TRACKER.PORT,
                    wssPort: TRACKER.PORT,
                    wsPath: '',
                    disableStats: true,
                    authEndpoint: TRACKER.AUTH_ENDPOINT,
                    cluster: TRACKER.CLUSTER,
                    auth: {
                        headers: {
                            Authorization: `Bearer ${ authToken }`
                        }
                    },
                    enabledTransports: [ 'ws', 'wss' ]
                })

                window.pusher.subscribe(`private-${ channel }`).bind(event, data => {
                    let newData = JSON.parse(data.position)

                    // Transform Data
                    const transformedNewData = transformSocketData(newData)

                    dispatch( updateTrackerData([ transformedNewData ]) )
                })

                // Add Channel to Global scope to unsubscribe
                window.pusherChannel = `private-${ channel }`
            })
            .catch(err => console.error(err))
    }
}

// Add/Update Barikoi Tracker Data
function updateTrackerData(newData) {
    return (dispatch, getState) => {
        // Get Current Datasets and Config
        const dataInfo = { id: TRACKER.DATA_ID, label: TRACKER.DATA_LABEL }
        const options = { readOnly: true, keepExistingConfig: true }
        const { zoom, latitude, longitude } = getState().keplerGl.map.mapState
        const trackerDataset = getState().keplerGl.map.visState.datasets[ TRACKER.DATA_ID ]

        // Upadte Users in Tracker Reducer
        dispatch( updateUsers(newData) )

        // Check if Tracker Dataset Exists
        if(trackerDataset) {
            // If Tracker Dataset exists

            // Process New Data
            let processedNewData = processRowObject(newData).rows

            // Check if same data Id exists in the dataset
            const dataLength = trackerDataset.allData.length
            const userIdIndex = trackerDataset.fields.findIndex(el => el.name === 'user_id')
            for(let i = 0; i < dataLength; i++) {
                const indexAt = processedNewData.findIndex(item => item[userIdIndex] === trackerDataset.allData[i][userIdIndex])
                if(indexAt >= 0) {
                    // Fill All Data except user_id and name
                    const length = trackerDataset.allData[i].length
                    for(let j = 2; j < length; j++) {
                        trackerDataset.allData[i][j] = processedNewData[indexAt][j]
                    }

                    processedNewData = processedNewData.filter(item => item[userIdIndex] !== trackerDataset.allData[i][userIdIndex])
                }
            }

            const data = {
                fields: trackerDataset.fields.map(field => ({
                    analyzerType: field.analyzerType,
                    format: field.format,
                    name: field.name,
                    tableFieldIndex: field.tableFieldIndex,
                    type: field.type
                })),
                rows: [ ...trackerDataset.allData, ...processedNewData ]
            }
            const dataset = { info: dataInfo, data }

            // Dispatch `addDataToMap`
            dispatch( addDataToMap({ datasets: dataset, options }) )

            // Update Map State to Current State
            dispatch( updateMap({ zoom, latitude, longitude }) )

        } else {
            // If Tracker Dataset doesn't exist
            // Build Dataset
            const data = processRowObject(newData)
            const dataset = { info: dataInfo, data }

            // Dispatch `addDataToMap`
            dispatch( addDataToMap({ datasets: dataset, options: { ...options, centerMap: true } }) )

            // Remove Point Layer and Set Layer Color
            const { layers } = getState().keplerGl.map.visState
            layers.forEach((layer, index) => {
                if(getLayerType(layer) === 'point') {
                    dispatch( removeLayer(index) )
                }
            })

            // Set Layer Label
            const trackerLayer = getState().keplerGl.map.visState.layers
                .find(l => l.config.dataId === dataInfo.id)
            if(trackerLayer) {
                dispatch( layerConfigChange(trackerLayer, { label: 'Trace' }) )
            }

            // Set Tracker Text Label to `name`
            const traceDataset = getState().keplerGl.map.visState.datasets[ TRACKER.DATA_ID ]
            const nameField = traceDataset.fields.find(el => el.name === 'name')
            if(nameField) {
                dispatch( setTextLabel(nameField) )
            }

            // Set Tooltip Propperties
            const fieldsToShow = [
                {
                    name: 'name',
                    format: null
                },
                {
                    name: 'updated_at',
                    format: null
                }
            ]
            const { tooltip } = getState().keplerGl.map.visState.interactionConfig
            tooltip.config.fieldsToShow[ TRACKER.DATA_ID ] = fieldsToShow
            dispatch( interactionConfigChange(tooltip) )
        }
    }
}

// Set Text Label to `name` Property for tracker Data
function setTextLabel(field) {
    return (dispatch, getState) => {
        // Set Text label
        const trackerLayer = getState().keplerGl.map.visState.layers.find(layer => layer.config.dataId === TRACKER.DATA_ID )
        const textField = {
            analyzerType: field.analyzerType,
            format: field.format,
            id: field.id,
            name: field.name,
            tableFieldIndex: field.tableFieldIndex,
            type: field.type
        }

        if(trackerLayer) {
            dispatch( layerTextLabelChange(trackerLayer, 0, 'field', textField) )
        }
    }
}

// Transform New API Data
function transformApiData(data) {
    const apiData = data.map(i => ({
        user_id: i.user_id,
        name: i.name,
        latitude: i.latitude,
        longitude: i.longitude,
        updated_at: i.updated_at,
        active_status: i.active_status === 1 ? 'online' : 'offline',
        icon: 'profile'
    }))

    return apiData
}

// Transform New Socket Data
function transformSocketData(data) {
    const socketData = {
        user_id: data.user_id,
        name: data.name,
        latitude: Number(data.latitude),
        longitude: Number(data.longitude),
        updated_at: data.updated_at,
        active_status: data.active_status === 1 ? 'online' : 'offline',
        icon: 'profile'
    }

    return socketData
}

// Get and Decrypt auth token from local storage
function getAuthToken() {
    const authToken = localStorage.getItem('token')
    return authToken
}

// Check Online Status
function checkOnlineStatus() {
    return (dispatch, getState) => {
        // Get Current Datasets and Config
        // Get Existing Data and update active_status
        const dataInfo = { id: TRACKER.DATA_ID, label: TRACKER.DATA_LABEL }
        const options = { readOnly: true, keepExistingConfig: true }
        const { zoom, latitude, longitude } = getState().keplerGl.map.mapState
        const trackerDataset = getState().keplerGl.map.visState.datasets[ TRACKER.DATA_ID ]

        // Get active_status Index
        const activeIndex = trackerDataset.fields.findIndex(el => el.name === 'active_status')
        const updatedAtIndex = trackerDataset.fields.findIndex(el => el.name === 'updated_at')

        // Check if same data Id exists in the dataset as active users
        const dataLength = trackerDataset.allData.length
        for(let i = 0; i < dataLength; i++) {
            // Check `updated_at`. If `online` and 2 minutes passed after `updated_at` then set `offline`
            const updatedAt = trackerDataset.allData[i][updatedAtIndex]
            const updatedAtDate = new Date(updatedAt)
            const updatedAtTime = updatedAtDate.getTime()
            const currentTime = Date.now()
            const elapsed = currentTime - updatedAtTime
            const activeStatus = trackerDataset.allData[i][activeIndex]
            const offlineThresholdDelay = 600000

            if(activeStatus === 'online' && elapsed >= offlineThresholdDelay) {
                trackerDataset.allData[i][activeIndex] = 'offline'
            }
        }

        const data = {
            fields: trackerDataset.fields.map(field => ({
                analyzerType: field.analyzerType,
                format: field.format,
                name: field.name,
                tableFieldIndex: field.tableFieldIndex,
                type: field.type
            })),
            rows: trackerDataset.allData
        }
        const dataset = { info: dataInfo, data }

        // Dispatch `addDataToMap`
        dispatch( addDataToMap({ datasets: dataset, options }) )

        // Update Map State to Current State
        dispatch( updateMap({ zoom, latitude, longitude }) )
    }
}

// Update Users
export function updateUsers(users) {
    return dispatch => {
        dispatch({ type: ActionTypes.UPDATE_USERS, payload: { users } })
    }
}

// Set Selected User
export function setSelectedUser(selectedUser) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_SELECTED_USER, payload: { selectedUser } })
    }
}

// Focus Selected User
export function focusSelectedUser(selectedUser) {
    return dispatch => {
        const { longitude, latitude } = selectedUser
        if(longitude && latitude) {
            dispatch( updateMap({ longitude, latitude, zoom: 23 }) )
        }
    }
}