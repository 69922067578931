import axios from 'axios'
import { processGeojson } from 'kepler.gl/processors'
import { addDataToMap, removeDataset, layerVisConfigChange, updateLayerAnimationSpeed, addLayer, layerTypeChange, layerConfigChange } from 'kepler.gl/actions'
import { getLayerType, setLayerSizeWithZoomLevel } from './layerActions'
import { setIsDataLoading } from './dataActions'
import * as ActionTypes from './actionTypes'

// Import Configs
import { ROUTE } from '../../App.config'

// Fetch and Display User Route
export function getAndDisplayUserRoute(routeParams) {
    return (dispatch, getState) => {
        // Set Route View Type based on params
        const routeUrl = routeParams.routeView === 'Polyline View' ?
            ROUTE.POLYLINE_API_URL :
            routeParams.routeView === 'Points View' ?
            ROUTE.POINTS_API_URL :
            ''
        
        // Set isDataLoading
        dispatch( setIsDataLoading(true) )

        // Get User Route
        axios.get(routeUrl, {
            params: {
                user_id: routeParams.userId,
                start_time_stamp: routeParams.startTime,
                end_time_stamp: routeParams.endTime
            }
        })
            .then(res => {
                // If Map doesn't exist
                if(!getState().keplerGl.map) {
                    return
                }
                
                // If Invalid Response
                if(!res.data.rdp_json && !res.data.data && res.data.message) {
                    dispatch( setRouteError({ message: res.data.message }) )
                    return
                }

                // Clear Previous Dataset
                dispatch( clearRouteDataset() )

                // Route Data
                const routeData = routeParams.routeView === 'Polyline View' ?
                    res.data.rdp_json :
                    routeParams.routeView === 'Points View' ?
                    res.data.data :
                    null

                if(routeParams.routeView === 'Polyline View') {
                    // Dispatch Trip Layer
                    dispatch( dispatchTripLayer(routeData) )

                } else if(routeParams.routeView === 'Points View') {
                    // Dispatch Points View Layer
                    dispatch( dispatchPointsViewLayer(routeData) )   
                }
            })
            .then(() => {
                // Set isDataLoading
                dispatch( setIsDataLoading(false) )
            })
            .catch(err => {
                if(err.message) {
                    dispatch( setRouteError({ message: err.message }) )
                }
                console.error(err)
            })
    }
}

// Dispatch Trip Layer Data
function dispatchTripLayer(tripData) {
    return (dispatch, getState) => {
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Build Route Dataset only for Route Layer if lesser than 3 coordinates exist as Trip layer throws error //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if(tripData && tripData.features[0].geometry.coordinates.length < 3) {
            // Remove Timestamps
            tripData.features[0].geometry.coordinates = tripData.features[0].geometry.coordinates
                .map(i => i.filter((j, index) => index < 3))

            // Build Dataset
            const dataInfo = { id: ROUTE.DATA_ID, label: ROUTE.DATA_LABEL }
            const data = processGeojson(tripData)
            const dataset = { info: dataInfo, data }

            // Options & Configs
            const options = { centerMap: true, keepExistingConfig: true }

            // Dispatch `addDataToMap`
            dispatch( addDataToMap({ datasets: dataset, options }) )

            // Apply Layer Configs
            let routeLayer = getState().keplerGl.map.visState.layers
                .find(l => l.config.dataId === dataInfo.id && getLayerType(l) === 'geojson')
            dispatch( layerConfigChange(routeLayer, { label: 'Route' }) )

            routeLayer = getState().keplerGl.map.visState.layers
                .find(l => l.config.dataId === dataInfo.id && getLayerType(l) === 'geojson')
            dispatch( layerVisConfigChange(routeLayer, { strokeColor: [ 18, 147, 154 ], thickness: 2 }) )

            // Set Layer Size with Zoom Level
            const zoom = getState().keplerGl.map.mapState.zoom
            dispatch( setLayerSizeWithZoomLevel(zoom) )

        } else {
            ////////////////////////////////////////////////////////
            // Build Route Dataset for regular Trip + Route Layer //
            ////////////////////////////////////////////////////////
            const dataInfo = { id: ROUTE.DATA_ID, label: ROUTE.DATA_LABEL }
            const data = processGeojson(tripData)
            const dataset = { info: dataInfo, data }

            // Options & Configs
            const options = { centerMap: true, keepExistingConfig: true }

            // Dispatch `addDataToMap`
            dispatch( addDataToMap({ datasets: dataset, options }) )

            // Set Trip Layer Config
            const tripLayer = getState().keplerGl.map.visState.layers
                .find(l => l.config.dataId === dataInfo.id && getLayerType(l) === 'trip')
            
            if(tripLayer) {
                // Set Label
                dispatch( layerConfigChange(tripLayer, { label: 'Trip', color: [ 254, 210, 26 ] }) )

                // Set Thickness
                dispatch( layerVisConfigChange(tripLayer, { thickness: 2, trailLength: 500 }) )

                // Set Animation Speed
                dispatch( updateLayerAnimationSpeed(0.5) )
            }

            // Add & Set Route GeoJSON Layer Config
            dispatch( addLayer({ dataId: dataInfo.id }) )

            let routeLayer = getState().keplerGl.map.visState.layers
                .find(l => Object.keys(l.meta).length === 0 && Object.keys(l.visConfigSettings).length === 0)
            dispatch( layerTypeChange(routeLayer, 'geojson') )

            routeLayer = getState().keplerGl.map.visState.layers
                .find(l => l.config.dataId === dataInfo.id && getLayerType(l) === 'geojson')
            dispatch( layerConfigChange(routeLayer, { label: 'Route', columns: { geojson: { fieldIdx: 0, value: '_geojson' }}}))

            routeLayer = getState().keplerGl.map.visState.layers
                .find(l => l.config.dataId === dataInfo.id && getLayerType(l) === 'geojson')
            dispatch( layerVisConfigChange(routeLayer, { strokeColor: [ 18, 147, 154 ], thickness: 2 }) )

            // Set Layer Size with Zoom Level
            const zoom = getState().keplerGl.map.mapState.zoom
            dispatch( setLayerSizeWithZoomLevel(zoom) )
        }
    }
}

// Dispatch Points Layer Data
function dispatchPointsViewLayer(routePointsData) {
    return (dispatch, getState) => {
        // Build Dataset
        const dataInfo = { id: ROUTE.DATA_ID, label: ROUTE.DATA_LABEL }
        const data = processGeojson(routePointsData)
        const dataset = { info: dataInfo, data }

        // Options & Configs
        const options = { centerMap: true, keepExistingConfig: true }

        // Dispatch `addDataToMap`
        dispatch( addDataToMap({ datasets: dataset, options }) )

        // Apply Layer Configs
        const { layers } = getState().keplerGl.map.visState
        layers.forEach(l => {
            if(l.config.dataId === dataInfo.id && getLayerType(l) === 'geojson') {
                dispatch( layerConfigChange(l, { label: 'Route', color: [ 18, 147, 154 ] }) )
            }
        })

        // Set Layer Size with Zoom Level
        const zoom = getState().keplerGl.map.mapState.zoom
        dispatch( setLayerSizeWithZoomLevel(zoom) )
    }
}

// Clear Route Dataset
export function clearRouteDataset() {
    return dispatch => {
        dispatch( removeDataset(ROUTE.DATA_ID) )
    }
}

// Dispatch `SET_ROUTE_USER_INFO`
export function setRouteUserInfo(routeUserInfo) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_ROUTE_USER_INFO, payload: { routeUserInfo } })
    }
}

// Dispatch `SET_SAVED_ANIMATION_CONFIG`
function setSavedAnimationConfig(savedAnimationConfig) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_SAVED_ANIMATION_CONFIG, payload: { savedAnimationConfig } })
    }
}

export function saveRouteAnimationConfig(newConfig=null) {
    return (dispatch, getState) => {
        // Get Route Animation Config
        const map = getState().keplerGl.map
        if(!map) {
            return
        }

        const animationConfig = newConfig ? newConfig : map.visState.animationConfig
        if(!animationConfig) {
            return
        }
        dispatch( setSavedAnimationConfig(animationConfig) )
    }
}

export function setRouteError(error) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_ROUTE_ERROR, payload: { error } })
    }
}