import { layerConfigChange, layerVisConfigChange, updateLayerAnimationSpeed, setLayerAnimationTime } from 'kepler.gl/actions'
import { saveRouteAnimationConfig } from './routeActions'

// Configs
import { TRACKER, ROUTE } from '../../App.config'

// Set Point Layer Radius based on Zoom Level
export function setLayerSizeWithZoomLevel(zoom) {
    return (dispatch, getState) => {
        // Dispatch Radius Change for each point layer
        const radius = getRadiusWithZoom(zoom)
        const iconLayers = getState().keplerGl.map.visState.layers.filter(layer => getLayerType(layer) !== 'point')
        iconLayers.forEach(iconLayer => {
            dispatch( layerVisConfigChange(iconLayer, { radius }) )
        })

        // Set Route Layer Thickness with Zoom
        const routeLayers = getState().keplerGl.map.visState.layers.filter(l => l.config.dataId === ROUTE.DATA_ID)
        if(routeLayers.length > 0) {
            const thickness = getThicknessWithZoom(zoom)

            routeLayers.forEach(l => {
                dispatch( layerVisConfigChange(l, { thickness }) )
            })
        }
    }
}

// Set Trace Layer Visibility
export function setTraceLayerVisibility(isVisible) {
    return (dispatch, getState) => {
        const map = getState().keplerGl.map
        if(!map) {
            return
        }

        const traceLayer = map.visState.layers.find(layer => layer.config.dataId === TRACKER.DATA_ID)
        if(!traceLayer) {
            return
        }

        // Set Visibility
        dispatch( layerConfigChange(traceLayer, { isVisible }) )
    }
}

// Set Route Layer Visibility
export function setRouteLayerVisibility(isVisible) {
    return (dispatch, getState) => {
        const map = getState().keplerGl.map
        if(!map) {
            return
        }

        const routeLayers = map.visState.layers.filter(layer => layer.config.dataId === ROUTE.DATA_ID)

        // Save animation Config if not visible
        if(!isVisible) {
            dispatch( saveRouteAnimationConfig() )
        }
        
        // Set Visibility
        routeLayers.forEach(layer => {
            dispatch( layerConfigChange(layer, { isVisible }) )
        })

        // Apply Saved Animation Config if visible
        if(isVisible) {
            let animationConfig = getState().app.route.savedAnimationConfig

            // Apply Animation Speed
            dispatch( updateLayerAnimationSpeed(animationConfig.speed) )

            // Apply Animation Time
            dispatch( setLayerAnimationTime(animationConfig.currentTime) )
        }
    }
}

///////////////
// Utilities //
///////////////
export function getLayerType(curLayer) {
    // If Layer exists
    if(curLayer) {
        if(curLayer.visConfigSettings.radius && curLayer.visConfigSettings.filled && !curLayer.visConfigSettings.wireframe) {
            // Point Type
            return 'point'

        } else if(curLayer.config.columns.geojson && curLayer.visConfigSettings.radius && curLayer.visConfigSettings.filled && curLayer.visConfigSettings.wireframe) {
            // Polygon Type
            return 'geojson'

        } else if(curLayer.visConfigSettings.colorAggregation && !curLayer.visConfigSettings.resolution) {
            // Grid Type
            return 'grid'

        } else if(curLayer.visConfigSettings.colorAggregation && curLayer.visConfigSettings.resolution) {
            // Hexbin Type
            return 'hexagon'

        } else if(curLayer.computeHeatmapConfiguration) {
            // Heatmap Type
            return 'heatmap'

        } else if(curLayer.visConfigSettings.clusterRadius) {
            // Cluster Type
            return 'cluster'

        } else if(curLayer.dataToFeature && curLayer.dataToTimeStamp && curLayer.config.visConfig.trailLength) {
            // Trip Type
            return 'trip'
        }
    }

    return ''
}

// Get Radius with Zoom
function getRadiusWithZoom(zoom) {
    let radius = 30

    if(zoom >= 14 && zoom < 15) {
        radius = 20

    } else if(zoom >= 15 && zoom < 16) {
        radius = 12.0

    } else if(zoom >= 16 && zoom < 17) {
        radius = 7.0

    } else if(zoom >= 17 && zoom < 18) {
        radius = 4.0

    } else if(zoom >= 18 && zoom < 19) {
        radius = 2.0

    } else if(zoom >= 19 && zoom < 20) {
        radius = 1.0

    } else if(zoom >= 20 && zoom < 22) {
        radius = 0.5

    } else if(zoom >= 22 && zoom < 23) {
        radius = 0.3

    } else if(zoom >= 23 && zoom < 24) {
        radius = 0.2

    } else if(zoom >= 24) {
        radius = 0.1
    }

    return radius
}

// Get Thickness With Zoom
function getThicknessWithZoom(zoom) {
    let thickness = 2

    if(zoom > 15 && zoom <= 16) {
        thickness = 1

    } else if(zoom > 16 && zoom <= 17) {
        thickness = 0.5

    } else if(zoom > 17 && zoom <= 18) {
        thickness = 0.4

    } else if(zoom > 18 && zoom <= 19) {
        thickness = 0.3

    } else if(zoom > 19 && zoom <= 20) {
        thickness = 0.2

    } else if(zoom > 20) {
        thickness = 0.1
    }

    return thickness
}