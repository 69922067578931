import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import RouteSelectMenu from './RouteSelectMenu'
import LogoutButton from '../Tracker/LogoutButton'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

// Import Actions & Methods
import { setRouteError } from '../../store/actions/routeActions'

class AnalyticsPanel extends React.PureComponent {
    // Handle Snackbar Close
    handleSnackbarClose = (event, reason) => {
        if(reason === 'clickaway') {
            return
        }

        this.props.dispatch( setRouteError({}) )
    }

    render() {
        const { error } = this.props

        return (
            <Box { ...containerStyles }>
                <RouteSelectMenu />
                <LogoutButton />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    open={ error.message && error.message !== '' }
                    autoHideDuration={ 5000 }
                    message={ error.message }
                    onClose={ this.handleSnackbarClose }
                    action={
                        <React.Fragment>
                            <IconButton size='small' aria-label='close' color='inherit' onClick={ this.handleSnackbarClose }>
                                <CloseIcon fontSize='small' />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    margin: 0,
    padding: '8px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'auto'
}

// Prop Types
AnalyticsPanel.propTypes = {
    error: PropTypes.object
}

AnalyticsPanel.defaultProps = {
    error: {}
}

const mapStateToProps = state => ({
    error: state.app.route.error
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPanel)