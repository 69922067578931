// Side Panel Reducer Action Types
export const SET_IS_SIDE_PANEL_OPEN = 'SET_IS_SIDE_PANEL_OPEN'
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB'

// Tracker Action Types
export const SET_IS_TRACKER_ON = 'SET_IS_TRACKER_ON'
export const UPDATE_USERS = 'UPDATE_USERS'
export const SET_SELECTED_USER = 'SET_SELECTED_USER'

// Trace APP Authentication Action Types
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'

// Route Action Types
export const SET_ROUTE_USER_INFO = 'SET_ROUTE_USER_INFO'
export const SET_SAVED_ANIMATION_CONFIG = 'SET_SAVED_ANIMATION_CONFIG'
export const SET_ROUTE_ERROR = 'SET_ROUTE_ERROR'

// Data Reducer Actions
export const SET_IS_DATA_LOADING = 'SET_IS_DATA_LOADING'