import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import StyledDrawer from '../common/StyledDrawer'
import Box from '@material-ui/core/Box'
import Header from './Header'
import Footer from './Footer'
import Body from './Body'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

// Import Actions & Methods
import { setIsSidePanelOpen } from '../../store/actions/sidePanelActions'

class SidePanel extends React.PureComponent {
    // Handle toggle Side Panel
    openSidePanel = () => {
        this.props.dispatch( setIsSidePanelOpen(true) )
    }

    closeSidePanel = () => {
        this.props.dispatch( setIsSidePanelOpen(false) )
    }

    render() {
        const { isSidePanelOpen } = this.props

        return (
            <Box>
                { !isSidePanelOpen &&
                    <IconButton style={ iconStyles } onClick={ this.openSidePanel } >
                        <ChevronRightIcon style={{ fontSize: '20px', color: '#f5f5f5', padding: '6px' }} />
                    </IconButton>
				}

                <StyledDrawer
                    open={ isSidePanelOpen }
                    onClose={ this.closeSidePanel }
                    variant='persistent'
                >
                    <Box { ...drawerContainerStyles }>
                        <Header closeSidePanel={ this.closeSidePanel } title={ 'Trace' } />
                        <Body />
                        <Footer footerTitle={ 'Barikoi Technologies Limited' } />
                    </Box>
                </StyledDrawer>
            </Box>
        )
    }
}

// JSS Styles
const drawerContainerStyles = {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden'
}

const iconStyles = {
    position: 'absolute',
    zIndex: 10,
    left: '12px',
    top: '12px',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    background: 'rgba(56, 64, 78, 0.8)'
}

// Prop Types
SidePanel.propTypes = {
    isSidePanelOpen: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({ isSidePanelOpen: state.app.sidePanel.isSidePanelOpen })
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel)