import React from 'react'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

// Actions & Methods
import { handleLogout } from '../../store/actions/authActions'

class LogoutButton extends React.PureComponent {
    // Handle App Logout
    handleAppLogout = () => {
        this.props.dispatch( handleLogout() )
    }

    render() {
        return (
            <Box mt='auto' ml='auto' mb='8px' mr='8px'>
                <Button
                    variant='outlined'
                    color='primary'
                    onClick={ this.handleAppLogout }
                    style={{ color: '#fff', textTransform: 'none' }}
                >
                    { 'Logout' }
                </Button>
            </Box>
        )
    }
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(LogoutButton)