import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TRACKER } from '../../App.config'

// Import Components
import Box from '@material-ui/core/Box'
import CountCard from '../Tracker/CountCard'
import LogoutButton from '../Tracker/LogoutButton'
import UserList from './UserList'

class DashboardPanel extends React.PureComponent {
    render() {
        const { numOfUsers, usersOnline } = this.props

        return (
            <Box { ...containerStyles }>
                <CountCard title='Total Users' count={ numOfUsers } />
                <CountCard title='Users Online' count={ usersOnline } />
                <UserList />
                <LogoutButton />
            </Box>
        )
    }
}

// JSS Styles
const containerStyles = {
    margin: 0,
    padding: '8px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflow: 'auto'
}

// Prop Types
DashboardPanel.propTypes = {
    numOfUsers: PropTypes.number,
    usersOnline: PropTypes.number
}

DashboardPanel.defaultProps = {
    numOfUsers: 0,
    usersOnline: 0
}

const mapStateToProps = state => ({
    numOfUsers: state.keplerGl.map &&
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ] &&
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ].allData ?
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ].allData.filter(i => i[2] && i[3]).length
        : 0,
    usersOnline: state.keplerGl.map &&
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ] &&
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ].allData ?
        state.keplerGl.map.visState.datasets[ TRACKER.DATA_ID ].allData.filter(i => i.includes('online')).length
        : 0
})

export default connect(mapStateToProps, null)(DashboardPanel)