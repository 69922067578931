import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2ddbac'
        },
        secondary: {
            main: '#303840'
        }
    }
})

// Theme Props For Components
theme.props = {
    MuiDrawer: {
        variant: 'persistent'
    },

    // Tabs menu
    MuiAppBar: {
        position: 'relative',
    },

    MuiTabs: {
        variant: 'fullWidth',
        scrollButtons: 'auto'
    }
}

// Theme Style Overrides For Components
theme.overrides = {
    MuiDrawer: {
        paper: {
            background: 'transparent',
            border: 'none'
        },
        paperAnchorDockedLeft: {
            border: 'none !important'
        }
    },
    
    // Tabs menu
    MuiAppBar: {
        root: {
            flexGrow: 1,
            maxWidth: '100%',
        },
        colorPrimary: {
            backgroundColor: 'rgba(56, 64, 78, 0.6)'
        }
    },

    MuiTabs: {
        root: {
            minHeight: '40px',
            height: '40px',
            color: 'white',
        },
        indicator: {
            background: 'rgba(45, 219, 172, 0.8)'
        }
    },

    MuiTab: {
        root: {
            minHeight: '40px !important',
            height: '40px !important',
            minWidth: '20px !important',
            width: '48px !important',
        },
        labelIcon: {
            fontSize: '8px'
        }
    },
}

export default theme