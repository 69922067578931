import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _throttle from 'lodash/throttle'

// Import Components
import Box from '@material-ui/core/Box'
import KeplerGl from './KeplerGl'
import SidePanel from '../SidePanel/SidePanel'

// Import Global Variables and Configs
import { MAP_CONFIG } from '../../App.config'

// Import Actions & Methods
import { loadInitialCustomMap } from '../../store/actions/mapActions'
import { setLayerSizeWithZoomLevel } from '../../store/actions/layerActions'
import { focusSelectedUser } from '../../store/actions/trackerActions'

class KeplerGlMap extends React.PureComponent {
    state = {
        mapWidth: window.innerWidth,
        mapHeight: window.innerHeight
    }

    componentDidMount() {
        // Add Window Resize Event
        this.onResize = _throttle(this.updateAppSize, 0, { trailing: true })
        window.addEventListener('resize', this.onResize)

        // Load Initial Custom Map Without Data
        this.props.dispatch( loadInitialCustomMap() )
    }

    componentDidUpdate(prevProps) {
        const { zoom, selectedUser, dispatch } = this.props

        // Set Point Layer Radius with Zoom Level Change
        if(prevProps.zoom !== zoom) {
            this.props.dispatch( setLayerSizeWithZoomLevel(zoom) )
        }

        // If Selected User Changes
        if(prevProps.selectedUser.user_id !== selectedUser.user_id) {
            // Focus Selected user
            dispatch( focusSelectedUser(selectedUser) )
        }
    }

    updateAppSize = () => {
        this.setState({
            mapWidth: window.innerWidth,
            mapHeight: window.innerHeight
        })
    }

    render() {
        const { mapWidth, mapHeight } = this.state

        return (
            <Box { ...mapContainerStyles }>
                <KeplerGl
                    id='map'
                    mapboxApiAccessToken={ MAP_CONFIG.MAPBOX_TOKEN }
                    width={ mapWidth }
                    height={ mapHeight }
                />

                <SidePanel />
            </Box>
        )
    }
}

// JSS Styles
const mapContainerStyles = {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
}

// Prop Types
KeplerGlMap.propTypes = {
    zoom: PropTypes.number,
    selectedUser: PropTypes.object
}

KeplerGlMap.defaultProps = {
    zoom: 0,
    selectedUser: {}
}

const mapStateToProps = state => ({
    zoom: state.keplerGl.map ? state.keplerGl.map.mapState.zoom : 0,
    selectedUser: state.app.tracker.selectedUser
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(KeplerGlMap)