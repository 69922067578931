// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import PersonPinIcon from '@material-ui/icons/PersonPin'
import TimelineIcon from '@material-ui/icons/Timeline'

class StyledTabsMenu extends React.PureComponent {
    render() {
        const { width, value, iconSize, onChange } = this.props

        return (
            <AppBar position='relative' style={{ width }}>
                <Tabs
                    value= { value }
                    onChange = { onChange }
                >
                    <Tab icon={ <PersonPinIcon style={{ fontSize: iconSize }} /> } />
                    <Tab icon={ <TimelineIcon style={{ fontSize: iconSize }} /> } />
                </Tabs>
            </AppBar>
        )
    }
}

StyledTabsMenu.propTypes = {
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    iconSize: PropTypes.string,
    onChange: PropTypes.func.isRequired
}
  
StyledTabsMenu.defaultProps = {
    value: 0,
    width: '100%'
}

export default StyledTabsMenu