import * as ActionTypes from '../actions/actionTypes'

const initialState = {
    error: {
        message: ''
    }
}

const authReducer = (state=initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_AUTH_ERROR:
            return {
                error: { message: action.payload.message }
            }
        
        default:
            return state
    }
}

export default authReducer