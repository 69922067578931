import * as ActionTypes from '../actions/actionTypes'

const initialState = {
    isDataLoading: false
}

const dataReducer = (state=initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_IS_DATA_LOADING:
            return {
                ...state,
                isDataLoading: action.payload.isDataLoading
            }
        
        default:
            return state
    }
}

export default dataReducer